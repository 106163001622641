import { Link } from "gatsby";
import React, { useContext } from "react"; 
import { FirebaseAuthContext, Logout } from "../../context/firebase-auth";

interface HomeCardLinkProps {
    PathName: string;
    Name: string;
}
const HomeCardLink = (props: HomeCardLinkProps) => {
    return (
        <Link to={props.PathName} className="tw-w-full tw-cursor-pointer tw-p-3">
            <div className="tw-w-full tw-min-h-[200px] tw-border tw-border-safari-green tw-flex tw-justify-center tw-items-center tw-rounded">
                <h3 className="tw-text-xl">
                    {props.Name}
                </h3>
            </div>
        </Link>
    )
};

const UserHome = () => {
    const user = useContext(FirebaseAuthContext);
    return (
        <div className="tw-flex tw-justify-center">
        <div className="tw-w-full tw-max-w-[1000px]">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-my-8">
                <h3 className="tw-text-safari-green tw-text-4xl tw-font-bold">
                    Welcome to CloudSafari!
                </h3>
                <h5 className="tw-text-lg tw-mt-2">
                    Use the quick links below to access messages, your profile, and more.
                </h5>
            </div>

            <div className="tw-flex tw-flex-col tw-justify-center">
            
            <div className="tw-w-full tw-flex">
                    <HomeCardLink
                        PathName="/user/messages"
                        Name="Messages"
                    />
                    <HomeCardLink
                        PathName={`/user/profile/${user?.uid}`}
                        Name="Profile"
                    />
                </div>

            <div className="tw-flex tw-my-8">
                <button onClick={() => {
                    Logout("/");
                }} className="tw-red-button">
                    Logout
                </button>
            </div>

            </div>
        </div>
        </div>
    )
};

export default UserHome;